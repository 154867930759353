<template >
  <ion-page>
    <Header type="0" title="问题记录" v-if="menuRole == 'phone'"> </Header>

    <ion-content scroll-y="false" class="content" id="stageCodeSearch">
      <div class="root">
        <div class="item">
          <div class="item remark">
            <ion-text class="left">
              <span class="required">*</span>问题描述</ion-text
            >
            <div style="max-height: 150px; width: 100%; overflow: auto">
              <ion-textarea
                auto-grow="true"
                class="right"
                placeholder="请输入您的描述..."
                maxlength="500"
                v-model.trim="formData.problemDescribe"
              ></ion-textarea>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item remark">
            <ion-text class="left">
              <span class="required">*</span>拍照上传</ion-text
            >
            <div style="width: 100%">
              <upload-img
                @modelBack="modelBack"
                :maxLength="5"
                :form="formData"
                photo="uploadImgList"
                :id="formData.id"
              ></upload-img>
            </div>
          </div>
        </div>
        <div class="item" @click="getTaskCode">
          <div class="left"><span class="required">*</span>试制任务单号</div>
          <div class="right">
            <ion-text>
              {{ formData.stageCode }}
            </ion-text>
            <span class="iconfont icon-jinru"></span>
          </div>
        </div>
        <div class="item" @click="getPartCode">
          <div class="left"><span class="required">*</span>零部件编号</div>
          <div class="right">
            <ion-text>
              {{ formData.partCode }}
            </ion-text>
            <span class="iconfont icon-jinru"></span>
          </div>
        </div>
         <div class="item">
          <div class="left"><span class="required">*</span>设计车型号</div>
          <div class="right">
            <ion-select
              placeholder="请选择设计车型号"
              @ionChange="ionChangeCXH()"
              v-model="formData.busId"
              mode="ios"
                  okText="确定"
              cancelText="取消"
              interface="action-sheet"
            >
              <ion-select-option
                v-for="(item, index) in cxhOptions"
                :key="index"
                :value="item.busId"
                >{{
                  item.partCode
                }}</ion-select-option
              >
            </ion-select>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>设计序号</div>
          <div class="right">
            <ion-select
              placeholder="请选择设计序号"
              @ionChange="ionChangeXH(formData.designNumberId)"
              v-model="formData.designNumberId"
              mode="ios"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
              multiple="true"
            >
              <ion-select-option
                v-for="(item, index) in designOptions"
                :key="index"
                :value="item.designNumberId"
                >{{
                  item.designNumber + "  |  " + item.configInfo
                }}</ion-select-option
              >
            </ion-select>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>车辆编号</div>
          <div class="right">
            <ion-select
              placeholder="请选择车辆编号"
              v-model="formData.vehicleId"
              okText="确定"
              cancelText="取消"
              interface="action-sheet"
              multiple="true"
            >
              <ion-select-option
                v-for="(item, index) in vehicelOptions"
                :key="index"
                :value="item.id"
                >{{ item.vehicleCode }}</ion-select-option
              >
            </ion-select>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-button expand="block" @click="commit(1)">提交</ion-button>
      <ion-button expand="block" @click="commit(2)">提交并新建</ion-button>
    </ion-footer>
    <search-task-code @chooseCode="chooseCode"></search-task-code>
    <search-part-code @choosePart="choosePart"></search-part-code>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { menuController, alertController, loadingController } from "@ionic/vue";
import {
  createProblemItem,
  getDesignNumber,
  getVehicleCode,
  updateProblemItem,
} from "@/api/questionManage/questionAdd";
import searchTaskCode from "./searchTaskCode.vue";
import searchPartCode from "./searchPartCode.vue";
import Utils from "@/utils/Utils.ts";
import * as dd from "dingtalk-jsapi";
import { getProjectStage } from "@/api/questionManage/questionAdd";
export default {
  components: {
    Header,
    uploadImg,
    searchTaskCode,
    searchPartCode
  },
  data() {
    return {
      formData: {
        id: "",
        projectId: "",
        vehicleCode: [],
        designNumber: [],
        uploadImgList: [],
        problemDescribe: "如:XX位置的XX零件与XX零件干涉",
        designNumberId: [],
        vehicleId: [],
        busId:'',
        truckModel:'',
        truckModelVersion:''
      },

      isDrag: false,
      cxhOptions: [], //设计车型号list
      designOptions: [], //设计序号list
      vehicelOptions: [], //车辆编号
      menuRole: "phone",
    };
  },
  mounted() {
    var _this = this;
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: true, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "扫一扫", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */

            _this.transfer();
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "问题记录", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    createProblemItem().then((res) => {
      var data = res.data.data.resultData[0];
      this.formData.id = data.id;
      // this.test({type:1,stageCode:'2020SZ04799'})
    });
  },
  methods: {
    transfer() {
      var _this = this;
      dd.ready(function () {
        dd.biz.util.scan({
          type: "all", // type 为 all、qrCode、barCode，默认是all。
          onSuccess: function (data) {
            //onSuccess将在扫码成功之后回调
            /* data结构
                         { 'text': String}
                         */
            const info = JSON.parse("{" + data.text + "}");
            if (info.type == 1 && info.stageCode) {
              getProjectStage({ stageCode: info.stageCode }).then(
                async (res) => {
                   _this.chooseCode(res.data.data.resultData[0] || {});
                  // getDesignNumber({
                  //   stageCode: info.stageCode,
                  //   vehicleId: info.id,
                  // }).then(async (res) => {
                  //   var desiList = res.data.data.resultData;
                  //   _this.formData.designNumberId = [];
                  //   desiList.forEach((e) => {
                  //     _this.formData.designNumberId.push(e.id);
                  //   });
                  //   await _this.ionChangeXH();

                  //   _this.formData.vehicleId = [];
                  //   _this.formData.vehicleId.push(Number(info.id));
                  // });
                }
              );
            } else {
              Utils.presentToastTopWarning("请扫描车辆二维码", "danger");
            }
          },
          onFail: function (err) {},
        });
      });
    },
    getTaskCode() {
      menuController.enable(true, "searchTask");
      menuController.open("searchTask");
    },
    getPartCode() {
      menuController.enable(true, "searchPart");
      menuController.open("searchPart");
    },
    test(info){
      var _this  = this
       if (info.type == 1 && info.stageCode) {
              getProjectStage({ stageCode: info.stageCode }).then(
                async (res) => {
                   _this.chooseCode(res.data.data.resultData[0] || {});
                  // getDesignNumber({
                  //   stageCode: info.stageCode,
                  //   vehicleId: info.id,
                  // }).then(async (res) => {
                  //   var desiList = res.data.data.resultData;
                  //   _this.formData.designNumberId = [];
                  //   desiList.forEach((e) => {
                  //     _this.formData.designNumberId.push(e.id);
                  //   });
                  //   await _this.ionChangeXH();

                  //   _this.formData.vehicleId = [];
                  //   _this.formData.vehicleId.push(Number(info.id));
                  // });
                }
              );
            } else {
              Utils.presentToastTopWarning("请扫描车辆二维码", "danger");
            }
    },
    /* 选择任务单 */
    chooseCode(e) {
        this.formData.projectId = e.projectId;
        this.formData.projectCode = e.projectCode;
        this.formData.projectName = e.projectName;
        this.formData.stageId = e.stageId;
        this.formData.stageCode = e.stageCode;
        this.formData.stageDesc = e.stageDesc;

        this.formData.designNumber = [];
        this.formData.designNumberId = [];
        this.designOptions = [];
        this.formData.vehicleCode = [];
        this.formData.vehicleId = [];
        this.vehicelOptions = [];
        this.cxhOptions = e.partLevelZeroList||[]
        /* 如果只有一条设计车型号直接带出所有下拉选 */
        if(e.partLevelZeroList.length==1){
          this.formData.busId = e.partLevelZeroList[0].busId
          this.formData.truckModel = e.partLevelZeroList[0].partCode
          this.formData.truckModelVersion = e.partLevelZeroList[0].partVersion
          // this.ionChangeCXH(e.partLevelZeroList[0].busId)
        }else{
          this.formData.busId = ''
        }
    },
    /* 选择零件号 */
    choosePart(e) {
        this.formData.partId = e.id;
        this.formData.partClassId = e.partClassId;
        this.formData.partClassCN = e.partClassCN;
        this.formData.partCode = e.partCode;
        this.formData.partNameCN = e.partNameCN;
        this.formData.partVersion = e.partVersion;
        this.formData.supplierId = e.supplierId;
        this.formData.supplierCN = e.supplierCN;
        this.formData.headPersonId = e.headPersonId;
        this.formData.headPerson = e.headPerson;
        this.formData.headPersonCode = e.headPersonCode;
        this.formData.headSpecialityId = e.headSpecialityId;
        this.formData.headSpeciality = e.headSpeciality;
    },
    /* 设计车型号change */
    ionChangeCXH(){
       this.formData.designNumber = [];
        this.formData.designNumberId = [];
        this.designOptions = [];
        this.formData.vehicleCode = [];
        this.formData.vehicleId = [];
        this.vehicelOptions = [];
        this.$nextTick(()=>{
            this.cxhOptions.forEach(e=>{
          if(e.busId==this.formData.busId){
             this.formData.truckModel = e.partCode
          this.formData.truckModelVersion = e.partVersion
          }
        })
        })
       
        if(this.formData.busId){
          getDesignNumber({ stageCode: this.formData.stageCode,busId:this.formData.busId }).then((res) => {
          this.designOptions = res.data.data.resultData;
          this.designOptions = this.designOptions.filter((e,i)=>{
            var bool = true
            this.designOptions.forEach((ele,idx)=>{
              if(idx>i&&ele.designNumberId==e.designNumberId){
                bool = false
              }
            })
            return bool
          })
          this.$nextTick(()=>{
             /* 如果只有一条设计车型号直接带出所有下拉选 */
            var list = []
            var list1 = []
            this.designOptions.forEach(e=>{
              list.push(e.designNumber)
              list1.push(e.designNumberId)
            })
             this.formData.designNumber = list
             this.formData.designNumberId = list1
          })
        });
        }
    },
    // 图片上传
    modelBack(data) {
      this.formData.uploadImgList = data;
    },
    // 设计序号change
    ionChangeXH() {
      return new Promise((resolve) => {
        this.formData.vehicleCode = [];
        this.formData.vehicleId = [];
        this.vehicelOptions = [];
       if(this.formData.designNumberId.length>0){
          getVehicleCode({
          stageCode: this.formData.stageCode,
          designNumberId: this.formData.designNumberId.join(","),
        }).then((res) => {
          this.vehicelOptions = res.data.data.resultData;
           /* 如果只有一条设计车型号直接带出所有下拉选 */
             var list = []
            var list1 = []
            this.vehicelOptions.forEach(e=>{
              list.push(e.vehicleCode)
              list1.push(e.id)
            })
             this.formData.vehicleCode = list
             this.formData.vehicleId = list1
          resolve();
        });
       }
      });
    },
    // ionChangeVe(id) {
    //   this.vehicelOptions.forEach((e) => {
    //     if (e.id == id) {
    //       this.formData.vehicleCode = e.vehicleCode;
    //     }
    //   });
    // },
    async commit(type) {
      const _this = this;
      if (!_this.formData.problemDescribe) {
        Utils.presentToastTopWarning("请输入您的描述", "danger");
        return false;
      } else if (
              !_this.formData.uploadImgList ||
              _this.formData.uploadImgList.length == 0
      ) {
        Utils.presentToastTopWarning("请上传问题照片", "danger");
        return false;
      } else if (!_this.formData.stageId) {
        Utils.presentToastTopWarning("请选择任务单", "danger");
        return false;
      }else if (!_this.formData.busId) {
        Utils.presentToastTopWarning("请选择设计车型号", "danger");
        return false;
      } else if (_this.formData.designNumberId.length == 0) {
        Utils.presentToastTopWarning("请选择设计序号", "danger");
        return false;
      } else if (_this.formData.vehicleId == 0) {
        Utils.presentToastTopWarning("请选择车辆编号", "danger");
        return false;
      }
      var data = JSON.parse(JSON.stringify(_this.formData));
    //  if(this.cxhOptions.length>1){
    //     _this.formData.vehicleId.forEach((id) => {
    //     this.vehicelOptions.forEach((e) => {
    //       if (e.id == id) {
    //         this.formData.vehicleCode.push(e.vehicleCode);
    //       }
    //     });
    //   });
    //   _this.formData.designNumberId.forEach((id) => {
    //     this.designOptions.forEach((e) => {
    //       if (e.designNumberId == id) {
    //         this.formData.designNumber.push(e.designNumber);
    //       }
    //     });
    //   });
    //  }
      data.vehicleCode = this.formData.vehicleCode.join(",");
      data.vehicleId = _this.formData.vehicleId.join(",");
      data.designNumber = this.formData.designNumber.join(",");
      data.designNumberId = _this.formData.designNumberId.join(",");
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });

              await loading.present();

              updateProblemItem(data).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  if(type==2){
                    createProblemItem().then((res) => {
                      var data = res.data.data.resultData[0];
                      this.formData.id = data.id;
                      this.formData.problemDescribe = '';
                      this.formData.uploadImgList = [];
                      this.formData.partId = '';
                      this.formData.partClassId = '';
                      this.formData.partClassCN = '';
                      this.formData.partCode = '';
                      this.formData.partNameCN = '';
                      this.formData.partVersion = '';
                      this.formData.supplierId = '';
                      this.formData.supplierCN = '';
                      this.formData.headPersonId = '';
                      this.formData.headPerson = '';
                      this.formData.headPersonCode = '';
                      this.formData.headSpecialityId = '';
                      this.formData.headSpeciality = '';
                    });
                  }else{
                    _this.$router.go(-1);
                  }
                  Utils.presentToastTopWarning("提交成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {},
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
    },
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.remark {
  max-height: 280px;
  overflow: auto;
}
.root .remark .right {
  width: 100%;
}
</style>
<style lang="scss">
[aria-checked="true"].sc-ion-alert-ios .alert-checkbox-icon.sc-ion-alert-ios {
  background: #00529c !important;
}
</style>
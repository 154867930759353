<template >
  <ion-menu
    menu-id="searchTask"
    side="end"
    content-id="stageCodeSearch"
    :swipeGesture="false"
    @ionDidClose="dataTable = [],keyword=''"
  >
    <ion-content class="select-user-dept-team">
      <ion-header class="theme2">
        <ion-toolbar>
          <p slot="start" style="font-size:14px">
            <span class="iconfont icon-renwushaixuan"></span>&nbsp;任务单
          </p>
          <ion-buttons slot="end" class="close">
            <ion-button @click="closeModel">
              <span class="iconfont icon-guanbi"></span>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="meetingPeople">
        <ion-grid>
          <ion-row class="ion-row-flex">
            <ion-col size="12" class="ion-col-common add-text-align-left">
              <div class="searchBox">
                <SearchBar
                  :isAudio="false"
                  theme="1"
                   :searchValue="keyword"
                  @on-enter="searchHandler"
                  placeholder="搜索任务单"
                ></SearchBar>
              </div>
            </ion-col>
            
          </ion-row>
        </ion-grid>
        <!-- List of Text Items -->
        <ion-list>
          <ion-item
            v-for="(item, index) in dataTable"
            :key="index"
            class="itemsprojectuser"
            @click="onClose(item)"
          >
            <ion-label>{{ item.stageDesc }}</ion-label>
          
          </ion-item>
        </ion-list>
        <CardLoading
          v-if="dataTable.length <= 0 && islist"
          lodingType="1"
        ></CardLoading>
      </div>
    </ion-content>
  </ion-menu>
</template>
<script>
import { defineComponent } from "vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { menuController } from "@ionic/vue";
import {getProjectStage} from "@/api/questionManage/questionAdd"
import Utils from "../../utils/Utils";
export default defineComponent({
  props:{
      id:''
  },
  components: {
    CardLoading,
    Header,
    SearchBar,
  },
  data() {
    return {
      dataTable: [],
      islist: false,
      keyword:''
    };
  },
  methods:{
        initData() {
      this.islist = true;
      getProjectStage({stageCode:this.keyword}).then((res) => {
        this.islist = false;
        this.dataTable = res.data.data.resultData||[];
      });
    },
   
    searchHandler(searchParam) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
    
        this.keyword = searchParam.searchKey;
      
      this.initData()
    },
    onClose(item) {
      if(!item.assemblyTechnicianId){
         Utils.presentToastTopWarning("任务单没分配装配工艺员", "danger");
         return false
      }
     
      this.$emit("chooseCode", item);
      menuController.close();
    },
    closeModel(){

        menuController.close()
    }
  }
});
</script>